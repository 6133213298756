html {
  background-color: black;
  height: 100vh;
}
body {
  background-image: url("media/background-l-middle2.jpg");
  background-size: 1400px 787px;
  background-repeat: no-repeat;
  background-color: black;
  background-position: 50% 50%;

  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 900px) {
  .main-middle {
    max-width: 90%;
    margin-top: 1vh;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    background-color: rgba(40, 40, 40, 0.8);
  }
}

@media (min-width: 901px) {
  .main-middle {
    max-width: 726px;
    margin-top: 1.5vh;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    background-color: rgba(40, 40, 40, 0.8);
  }
}

h1 {
  color: #f9ba00;
  font-family: arista;
}

/* World Map */
.map-selected {
  fill: #ffc700;
}

.map-unselected {
  fill: #666666;
}

.map-unavailable {
  pointer-events: none;
  cursor: not-allowed;
  fill: #000000;
}

.map-selected:hover,
.map-unselected:hover {
  cursor: pointer;
  fill: #ffc700;
}

.customPopover {
  border-color: #ffc700;
  border-width: 1px;
}

a:link {
  color: #ffc700;
  text-decoration: none;
}
a:visited {
  color: #ffc700;
  text-decoration: none;
}
a:hover {
  color: #ffc700;
  text-decoration: none;
}
a:active {
  color: #ffc700;
  text-decoration: none;
}

.btn_ {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: static;
  width: 100px;
  left: calc(50% - 126px / 2);
  top: 0%;
  bottom: 0%;
  margin-top: 2%;
  background: #f97316;
  border-radius: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px;
}

.btn_:hover {
  background: #fb923c;
}

.btn_:active {
  background: #aaaaaa;
}

#paid_button {
  font-weight: 500;
  background-color: #22c55e;
  display: none;
}
#invoice-button {
  background-color: #22c55e;
}
.main-buttons button {
  margin: 5px 0px 5px 0px;
  padding: 15px;
  width: 100%;
}

.main-buttons {
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding-bottom: 10px;
}

.emailbuttons .modal-footer {
  display: flex;
}

.buttons Button {
  padding: 10px;
  margin: 10px;
}

.inputfield {
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
}

.input-group-text {
  min-width: 150px;
}

.price {
  color: #ffc700;
  text-align: center;
}
.price img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

.warning {
  color: #ff7700;
  text-align: center;
}

title {
  text-align: center;
}

/*
#countryselector {
  width: 100%;
  margin: 10px 0 10px 0;
  padding: 2px;
}
#countryselector label {
  width: 20%;
}
*/

#runtimeselector {
  width: 100%;
  margin: 10px 0 10px 0;
}
#runtimeselector label {
  text-align: center;
  width: 25%;
}
#runtimeselector label.btn.active {
  background-color: #ffc700;
  color: black;
}

#countryselector label.btn.active {
  background-color: #ffc700;
  color: black;
}

#invoicestring {
  text-align: left;
  margin-top: 10px;
  display: inline-block;
  border: 1px;
  padding: 5px;
  border-style: solid;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

#main_modal {
  min-width: 50%;
}

#main_modal img {
  max-width: 10%;
}

.modal-footer {
  display: contents;
}

#guide_row {
  text-align: right;
}

#emailModal span.input-group-text {
  min-width: 0;
}
.walletbutton {
  width: 100%;
}

#paid_message {
  text-align: center;
  color: green;
}

canvas {
  padding: 15px;
  background-color: white;
}

button.close {
  color: white;
}
.modal-content {
  border: 1px solid #375a7f !important;
}

/* footer */
.footer-text {
  text-align: center;
  color: #000000;
  margin: 20px;
}

.footer-text a {
  color: black;
}

.icon {
  display: inline-block;
  background-size: cover;
  width: 30px;
  height: 30px;
}
/*
.icon-sats {
  background-image: url("./media/sats-orange.png");
}
*/
.icon-nostr {
  background-image: url("./media/nostr-128.png");
  opacity: 0.5;
}
.icon-nostr:hover {
  background-image: url("./media/nostr-128c.png");
  opacity: 1;
}
.icon-twitter {
  background-image: url("./media/x-128.png");
  opacity: 0.5;
}
.icon-twitter:hover {
  background-image: url("./media/x-128.png");
  opacity: 1;
}
.icon-github {
  background-image: url("./media/github-128.png");
  opacity: 0.5;
}
.icon-github:hover {
  background-image: url("./media/github-128.png");
  opacity: 1;
}
.icon-heart {
  background-image: url("./media/hearts-128.png");
  opacity: 0.5;
}
.icon-heart:hover {
  background-image: url("./media/hearts-128c.png");
  opacity: 1;
}
.icon-telegram {
  background-image: url("./media/telegram-128.png");
  opacity: 0.5;
}
.icon-telegram:hover {
  background-image: url("./media/telegram-128c.png");
  opacity: 1;
}

#faq_modal {
  min-width: 50%;
}

#expirydate {
  color: #ffc700;
}

#dataplanlist {
  list-style: none;
}

img {
  padding: 5px;
  width: 100%;
  height: auto;
}

.logo {
  padding: 5px;
  width: 100%;
  height: auto;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000060;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 50%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: rgb(0, 0, 0);
  border-radius: 30px;
  padding: 20px;
  border: 1px solid #ffc700;
  overflow: auto;
}

/* Update Subscription CSS Formating */

.updateSubTitle {
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.titleSub {
  margin: 0;
}

.serverLabel {
  width: 75px;
  height: 33px;
}

